import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { cnpj } from 'cpf-cnpj-validator';
import icon from '../../../../assets/Images/icon-father.png';
import "../info-2/info-2.css";

function RegisterInfo2({ formData, setFormData, setRegisterStep }) {
    const [cnpjValue, setCnpjValue] = useState(formData.cnpj || '');
    const [cnpjError, setCnpjError] = useState('');
    const [fiscalClass, setFiscalClass] = useState(formData.fiscalClass || '');
    const [showOptions, setShowOptions] = useState(false);
    const [companyName, setCompanyName] = useState(formData.companyName || '');
    const [companySocialReason, setCompanySocialReason] = useState(formData.companySocialReason || '');

    const handleCNPJChange = (e) => {
        const value = e.target.value;
        setCnpjValue(value);
        if (cnpj.isValid(value)) {
            setCnpjError('');
        } else {
            setCnpjError('CNPJ inválido');
        }
    };

    const handleFiscalClassChange = (value) => {
        setFiscalClass(value);
        setShowOptions(false);
    };

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    };

    const handleNextClick = () => {
        setFormData({
            companyName,
            companySocialReason,
            cnpj: cnpjValue,
            fiscalClass
        });
        setRegisterStep(3); // Avance para a próxima etapa
    };

    // Define a cor do texto com base no valor de fiscalClass
    const getTextColor = () => {
        return fiscalClass ? 'text-list-item' : 'text-text-placeholder';
    };

    return (
        <section>
            <div>
                <section>
                    <div className='center-register'>
                        <div className='top-title-register'>
                            <img src={icon} alt="Icon" className='icon-father-login' />
                            <h2>Empresa</h2>
                            <p>Registrar agora!</p>
                        </div>
                        <div className='name-company-resp'>
                            <p>Nome da empresa <strong>(Fantasia)</strong></p>
                            <input
                                type='text'
                                placeholder='Digite o nome da empresa'
                                className='text-inpt'
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                        </div>

                        <div className='razao-company-resp'>
                            <p>Razão social</p>
                            <input
                                type='text'
                                placeholder='Digite a razão social da empresa'
                                className='text-inpt'
                                value={companySocialReason}
                                onChange={(e) => setCompanySocialReason(e.target.value)}
                            />
                        </div>

                        <div className='razao-company-resp'>
                            <p>CNPJ</p>
                            <InputMask
                                mask="99.999.999/9999-99"
                                value={cnpjValue}
                                onChange={handleCNPJChange}
                                placeholder='Digite o CNPJ da empresa'
                                className='text-inpt'
                            />
                        </div>

                        <div className='razao-company-resp'>
                            <p>Classificação fiscal</p>
                            <div className='relative w-full' onClick={toggleOptions}>
                                <div className={`bg-custom-blue rounded h-12 px-3 flex items-center justify-between cursor-pointer ${getTextColor()} font-inter font-regular`}>
                                    {fiscalClass || "Selecione"}
                                </div>
                                {showOptions && (
                                    <ul className='absolute bg-custom-gray rounded mt-1 w-full list-none'>
                                        <li 
                                            onClick={() => handleFiscalClassChange("SIMPLES NACIONAL")} 
                                            className={`p-2 cursor-pointer font-inter ${fiscalClass === "SIMPLES NACIONAL" ? 'bg-custom-gray text-list-item font-semibold' : 'text-list-item hover:bg-gray-300 font-semibold'}`}
                                        >
                                            SIMPLES NACIONAL
                                        </li>
                                        <li 
                                            onClick={() => handleFiscalClassChange("LUCRO PRESUMIDO")} 
                                            className={`p-2 cursor-pointer font-inter ${fiscalClass === "LUCRO PRESUMIDO" ? 'bg-custom-gray text-list-item font-semibold' : 'text-list-item hover:bg-gray-300 font-semibold'}`}
                                        >
                                            LUCRO PRESUMIDO
                                        </li>
                                        <li 
                                            onClick={() => handleFiscalClassChange("LUCRO REAL")} 
                                            className={`p-2 cursor-pointer font-inter ${fiscalClass === "LUCRO REAL" ? 'bg-custom-gray text-list-item font-semibold' : 'text-list-item hover:bg-gray-300 font-semibold'}`}
                                        >
                                            LUCRO REAL
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                        <button className='nxt-btn' onClick={handleNextClick}>Próximo</button>
                    </div>
                </section>
            </div>
        </section>
    );
}

export default RegisterInfo2;
