import React from 'react';
import "./footer.css";

function Footer() {

    return (
        <section className='line-footer'>
            <p>Copyright © 2024  Father & Company </p>

        </section>
    )

}


export default Footer;