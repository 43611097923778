import React from 'react';
import "../info-1/info-1.css";
import icon from '../../../../assets/Images/icon-father.png';

function RegisterInfo1({ setFormData, setRegisterStep }) {
    const handleButtonClick = (value) => {
        setFormData({
            country: value // Atualiza o campo country
        });
        setRegisterStep(2); // Avance para a próxima etapa
    };

    return (
        <section>
            <div>
                <section>
                    <div className='center-register'>
                        <div className='top-title-register'>
                            <img src={icon} alt="Icon" className='icon-father-login' />
                            <h2>Registrar</h2>
                            <p>Seja bem vindo!</p>
                        </div>
                        <div className='user-resp'>
                            <p>De que local a empresa é?</p>
                            <button onClick={() => handleButtonClick('Brasil')}>Brasil</button>
                            <button onClick={() => handleButtonClick('Outro país')}>Exterior</button>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
}

export default RegisterInfo1;
