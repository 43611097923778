import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import icon from '../../../../assets/Images/icon-father.png';
import apiService from '../../../../services/apiService';
import '../ct-info-1/info-1.css';

function CtInfo2({ formData, setFormData, setRegisterStep }) {
    const [birthDate, setBirthDate] = useState(formData.birthDate || '');

    const handleDateChange = (e) => {
        setBirthDate(e.target.value);
        setFormData(prevFormData => ({ ...prevFormData, birthDate: e.target.value }));
    };

    const handleNextStep = async () => {
        const updatedFormData = { ...formData, birthDate };

        // Validação básica
        if (!updatedFormData.contactName || !updatedFormData.email || !updatedFormData.phone) {
            console.error('Nome, e-mail e telefone são obrigatórios.');
            return;
        }

        // Formatar a data no formato ISO
        const formattedBirthDate = formatDateToISO(birthDate);

        const contactData = {
            name: updatedFormData.contactName,
            email: updatedFormData.email,
            phone: updatedFormData.phone,
            role: updatedFormData.role,
            sector: updatedFormData.sector,
            birthday: formattedBirthDate, // Data formatada para ISO
            cnpj: updatedFormData.cnpj
        };

        console.log('Enviando dados do contato para a API:', contactData);

        try {
            const response = await apiService.postContacts(contactData);
            console.log('Resposta da API:', response);
            setRegisterStep(prevStep => prevStep + 1);
        } catch (error) {
            console.error('Erro ao enviar dados do contato:', error);
            if (error.response) {
                console.error('Detalhes do erro:', error.response.data);
            }
        }
    };

    const formatDateToISO = (date) => {
        // Verificar se a data está no formato esperado
        const [day, month, year] = date.split('/');
        if (day && month && year) {
            const isoDate = new Date(`${year}-${month}-${day}`);
            // Verificar se a data é válida
            if (isNaN(isoDate.getTime())) {
                console.error('Data inválida:', date);
                return null;
            }
            return isoDate.toISOString();
        }
        console.error('Formato de data inválido:', date);
        return null;
    };

    return (
        <section>
            <div className='center-register'>
                <div className='top-title-register'>
                    <img src={icon} alt="Icon" className='icon-father-login' />
                    <h2>Contato</h2>
                    <p>Registrar agora!</p>
                </div>

                <div className='input-contact'>
                    <p>Data de Nascimento</p>
                    <InputMask
                        mask="99/99/9999"
                        value={birthDate}
                        onChange={handleDateChange}
                    >
                        {(inputProps) => <input {...inputProps} placeholder='DD/MM/AAAA' className='input-date' />}
                    </InputMask>
                </div>
                <button className='nxt-btn' onClick={handleNextStep}>Próximo</button>
            </div>
        </section>
    );
}

export default CtInfo2;
