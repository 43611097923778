import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import icon from '../../../../assets/Images/icon-father.png';
import addressData from '../../../../db/contact.json';
import '../ct-info-1/info-1.css';

function CtInfo1({ formData, setFormData, setRegisterStep }) {
    const [showRoleOptions, setShowRoleOptions] = useState(false);
    const [showSectorOptions, setShowSectorOptions] = useState(false);
    const [selectedRole, setSelectedRole] = useState(formData.role || '');
    const [selectedSector, setSelectedSector] = useState(formData.sector || '');

    useEffect(() => {
        if (formData.role !== selectedRole || formData.sector !== selectedSector) {
            setFormData({
                ...formData,
                role: selectedRole,
                sector: selectedSector
            });
        }
    }, [selectedRole, selectedSector, setFormData, formData.role, formData.sector]);
    

    const handleRoleChange = (role) => {
        setSelectedRole(role);
        setShowRoleOptions(false);
    };

    const toggleRoleOptions = () => {
        setShowRoleOptions(!showRoleOptions);
    };

    const handleSectorChange = (sector) => {
        setSelectedSector(sector);
        setShowSectorOptions(false);
    };

    const toggleSectorOptions = () => {
        setShowSectorOptions(!showSectorOptions);
    };

    const handleNextStep = () => {
        setFormData({
            ...formData,
            role: selectedRole,
            sector: selectedSector
        });
        setRegisterStep(prevStep => prevStep + 1);
    };

    const getTextColor = (value) => {
        return value ? 'text-list-item' : 'text-text-placeholder';
    };

    return (
        <section>
            <div className='center-register'>
                <div className='top-title-register'>
                    <img src={icon} alt="Icon" className='icon-father-login' />
                    <h2>Contato</h2>
                    <p>Registrar agora!</p>
                </div>

                <div className='input-contact first'>
                    <p>Nome do contato (Completo)</p>
                    <input placeholder='Digite o nome do contato' value={formData.contactName} onChange={(e) => setFormData({ ...formData, contactName: e.target.value })} />
                </div>

                <div className='input-contact'>
                    <p>E-mail</p>
                    <input placeholder='Digite o e-mail' value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                </div>

                <div className='input-contact'>
                    <p>Celular / WhatsApp</p>
                    <InputMask
                        mask="(99) 99999-9999"
                        value={formData.phone}
                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    >
                        {(inputProps) => <input {...inputProps} placeholder='(XX) XXXXX-XXXX' className='input-mask' />}
                    </InputMask>
                </div>

                <div className='input-contact'>
                    <p>Cargo</p>
                    <div className='relative w-full' onClick={toggleRoleOptions}>
                        <div className={`bg-custom-blue rounded h-12 px-3 flex items-center justify-between cursor-pointer ${getTextColor(selectedRole)} font-inter font-regular`}>
                            {selectedRole || "Selecione"}
                        </div>
                        {showRoleOptions && (
                            <ul className='absolute bg-custom-gray rounded mt-1 w-full list-none'>
                                {addressData.roles.map((role) => (
                                    <li
                                        key={role}
                                        onClick={() => handleRoleChange(role)}
                                        className={`p-2 cursor-pointer font-inter font-semibold ${selectedRole === role ? 'bg-custom-gray text-list-item' : 'text-list-item hover:bg-gray-300'}`}
                                    >
                                        {role}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>

                <div className='input-contact'>
                    <p>Setor</p>
                    <div className='relative w-full' onClick={toggleSectorOptions}>
                        <div className={`bg-custom-blue rounded h-12 px-3 flex items-center justify-between cursor-pointer ${getTextColor(selectedSector)} font-inter font-regular`}>
                            {selectedSector || "Selecione"}
                        </div>
                        {showSectorOptions && (
                            <ul className='absolute bg-custom-gray rounded mt-1 w-full list-none'>
                                {addressData.sectors.map((sector) => (
                                    <li
                                        key={sector}
                                        onClick={() => handleSectorChange(sector)}
                                        className={`p-2 cursor-pointer font-inter font-semibold ${selectedSector === sector ? 'bg-custom-gray text-list-item' : 'text-list-item hover:bg-gray-300'}`}
                                    >
                                        {sector}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>

                <button className='nxt-btn' onClick={handleNextStep}>Próximo</button>
            </div>
        </section>
    );
}

export default CtInfo1;
