import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Importar useNavigate do React Router
import { useAuth } from '../../auth/AuthContext';  // Contexto de autenticação
import "./login.css";
import users from '../../db/users.json';
import icon from '../../assets//Images/icon-father.png';
import Footer from '../components/Layout/footer/index';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { login } = useAuth();  // Obtenha a função de login do contexto
    const navigate = useNavigate();  // Inicialize o hook useNavigate

    const handleLogin = () => {
        const user = users.find(u => u.user === username && u.password === password);
        if (user) {
            login();  // Chame a função de login do contexto
            navigate('/');  // Redirecione para a rota home
        } else {
            setError('Usuário ou senha incorretos');
        }
    };

    return (
        <section className='screen'>
        <div className='center'>

            <div className='login-content'>
                <img src={icon} alt="Icon" className='icon-father-login' />
                <div className='login-title'>
                    <h1>Entrar</h1>
                    <p>Seja bem vindo!</p>
                </div>

                <div className='form-content'>
                    <div className='form-line-user'>
                        <label>Usuário</label>
                        <input
                            className='input-login'
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder='Digite seu login'
                        />
                    </div>

                    <div className='form-line-pass'>
                        <label>Senha</label>
                        <input
                            className='input-login'
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder='Digite sua senha'
                        />
                    </div>

                    <div>
                        <input
                            className='login-cta'
                            type="submit"
                            value="Entrar"
                            onClick={handleLogin}
                            placeholder='Entrar'
                        />
                    </div>
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
          
        </div>
        <Footer />
        </section>
       
    );
}

export default Login;
