import React, { useState, useEffect } from 'react';
import icon from '../../../../assets/Images/icon-father.png';
import "../status/status.css";
import apiService from '../../../../services/apiService'; // Importe o serviço da API

function Status({ formData, setFormData, setRegisterStep }) {
    const [imported, setImported] = useState(formData.imported || false);
    const [exported, setExported] = useState(formData.exported || false);
    const [importedProcesses, setImportedProcesses] = useState(formData.importedProcesses || '');
    const [exportedProcesses, setExportedProcesses] = useState(formData.exportedProcesses || '');

    useEffect(() => {
        if (!formData.cnpj) {
            setRegisterStep(1); 
        }
    }, [formData.cnpj, setRegisterStep]);

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        if (name === 'imported') {
            setImported(checked);
            setFormData(prevState => ({ ...prevState, imported: checked }));
        } else if (name === 'exported') {
            setExported(checked);
            setFormData(prevState => ({ ...prevState, exported: checked }));
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'importedProcesses') {
            setImportedProcesses(value);
            setFormData(prevState => ({ ...prevState, importedProcesses: value }));
        } else if (name === 'exportedProcesses') {
            setExportedProcesses(value);
            setFormData(prevState => ({ ...prevState, exportedProcesses: value }));
        }
    };

    const handleNextClick = async () => {
        const updatedFormData = {
            ...formData,
            imported,
            exported,
            importedProcesses,
            exportedProcesses
        };

        setFormData(updatedFormData);

        try {
            const statusMachData = {
                cnpj: updatedFormData.cnpj,
                importFreq: parseInt(importedProcesses) || 0, // Garantir que o valor seja um número
                exportFreq: parseInt(exportedProcesses) || 0, // Garantir que o valor seja um número
                importStatus: imported,
                exportStatus: exported,
                rfm: 0,
                status: ''
            };

            await apiService.postStatusMatches(statusMachData); // Chama o serviço da API para a rota /api/v2/statusmatches
            setRegisterStep(prevStep => prevStep + 1); // Avance para a próxima etapa
        } catch (error) {
            console.error('Erro ao enviar status de comércio:', error);
        }
    };

    return (
        <section>
            <div>
                <section>
                    <div className='center-register'>
                        <div className='top-title-register'>
                            <img src={icon} alt="Icon" className='icon-father-login' />
                            <h2>Empresa</h2>
                            <p>Registrar agora!</p>
                        </div>

                        <div className='line-status'>
                            <div className='line-labels'>
                                <div className='label-form'><p>Status COMEX</p></div>
                                <div className='label-form'><p>Último ano</p></div>
                            </div>
                            <div className='l-status'>
                                <div className='checkbox-container'>
                                    <input
                                        id="imported"
                                        type="checkbox"
                                        name="imported"
                                        checked={imported}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor="imported">
                                        Já importou?
                                    </label>
                                </div>
                                <div className='n-process'>
                                    <input
                                        name="importedProcesses"
                                        placeholder='Nº processos'
                                        value={importedProcesses}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className='r-status'>
                                <div className='checkbox-container'>
                                    <input
                                        id="exported"
                                        type="checkbox"
                                        name="exported"
                                        checked={exported}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor="exported">
                                        Já exportou?
                                    </label>
                                </div>
                                <div className='n-process'>
                                    <input
                                        name="exportedProcesses"
                                        placeholder='Nº processos'
                                        value={exportedProcesses}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <button className='nxt-btn' onClick={handleNextClick}>Próximo</button>
                    </div>
                </section>
            </div>
        </section>
    );
}

export default Status;
