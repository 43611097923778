import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../auth/AuthContext';
import Footer from '../../components/Layout/footer';
import icon from '../../../assets/Images/icon-father.png';

function Sucess({ resetFormData }) {
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    const handleBackClick = () => {
        resetFormData();
        navigate('/register'); // Redireciona para a rota /register
    };

    return (
        <section>
            {isAuthenticated ? (
                <div>
                    <section>
                        <div className='center-box-breve'>
                            <img src={icon} alt="Icon" className='icon-father-login' />
                            <h2>Sucesso</h2>
                            <p>Registrado com sucesso</p>
                            <div className='back-ct' onClick={handleBackClick}>
                                <p>Criar novo contato</p>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            ) : (
                <p>Por favor, faça login para acessar a rota Analytics.</p>
            )}
        </section>
    );
}

export default Sucess;
