import React, { useState } from 'react';
import icon from '../../../../assets/Images/icon-father.png';
import segmentData from '../../../../db/segment.json';
import addressData from '../../../../db/addressData.json';
import "../info-3/info-3.css";
import apiService from '../../../../services/apiService';

function RegisterInfo3({ formData, setFormData, setRegisterStep }) {
    const [segment, setSegment] = useState(formData.segment || '');
    const [subSegment, setSubSegment] = useState(formData.subSegment || '');
    const [state, setState] = useState(formData.state || '');
    const [city, setCity] = useState(formData.city || '');
    const [website, setWebsite] = useState(formData.website || '');
    const [address, setAddress] = useState(formData.address || ''); // Novo estado para o endereço
    const [showSegmentOptions, setShowSegmentOptions] = useState(false);
    const [showSubSegmentOptions, setShowSubSegmentOptions] = useState(false);
    const [showStateOptions, setShowStateOptions] = useState(false);
    const [showCityOptions, setShowCityOptions] = useState(false);

    const handleSegmentChange = (value) => {
        setSegment(value);
        setShowSegmentOptions(false);
    };

    const handleSubSegmentChange = (value) => {
        setSubSegment(value);
        setShowSubSegmentOptions(false);
    };

    const handleStateChange = (value) => {
        setState(value);
        setCity(''); // Limpa a cidade ao mudar o estado
        setShowStateOptions(false);
        setShowCityOptions(true);
    };

    const handleCityChange = (value) => {
        setCity(value);
        setShowCityOptions(false);
    };

    const handleWebsiteChange = (event) => {
        setWebsite(event.target.value);
    };

    const handleAddressChange = (event) => { // Função para atualizar o endereço
        setAddress(event.target.value);
    };

    const toggleSegmentOptions = () => {
        setShowSegmentOptions(!showSegmentOptions);
    };

    const toggleSubSegmentOptions = () => {
        setShowSubSegmentOptions(!showSubSegmentOptions);
    };

    const toggleStateOptions = () => {
        setShowStateOptions(!showStateOptions);
    };

    const toggleCityOptions = () => {
        setShowCityOptions(!showCityOptions);
    };

    const handleNextClick = async () => {
        const updatedFormData = {
            ...formData,
            segment,
            subSegment,
            state,
            city,
            website,
            address // Incluindo o endereço no formData atualizado
        };

        setFormData(updatedFormData);

        try {
            const companyData = {
                companyInfo: {
                    country: updatedFormData.country,
                    companyName: updatedFormData.companyName,
                    socialNameCompany: updatedFormData.companySocialReason,
                    CNPJ: updatedFormData.cnpj,
                    socialClass: updatedFormData.fiscalClass,
                    Segment: updatedFormData.segment,
                    SubSegment: updatedFormData.subSegment,
                    Website: updatedFormData.website,
                    State: updatedFormData.state,
                    City: updatedFormData.city,
                    Address: updatedFormData.address // Enviando o endereço para a API
                }
            };
            await apiService.postCompanies(companyData);
            setRegisterStep(4); // Avance para a próxima etapa
        } catch (error) {
            console.error('Erro ao enviar dados da empresa:', error);
        }
    };

    // Filtra estados e cidades com base no formato do JSON
    const estados = addressData.estados;
    const selectedState = estados.find(estado => estado.sigla === state);
    const cidades = selectedState ? selectedState.cidades : [];

    return (
        <section>
            <div>
                <section>
                    <div className='center-register'>
                        <div className='top-title-register'>
                            <img src={icon} alt="Icon" className='icon-father-login' />
                            <h2>Empresa</h2>
                            <p>Registrar agora!</p>
                        </div>

                        <div className='name-company-resp'>
                            <p>Segmento</p>
                            <div className='relative w-full' onClick={toggleSegmentOptions}>
                                <div className={`bg-custom-blue rounded h-12 px-3 flex items-center justify-between cursor-pointer ${segment ? 'text-list-item' : 'text-text-placeholder'} font-inter font-regular`}>
                                    {segment || "Selecione"}
                                </div>
                                {showSegmentOptions && (
                                    <ul className='absolute bg-custom-gray rounded mt-1 w-full list-none'>
                                        {segmentData.segments.map((seg) => (
                                            <li
                                                key={seg}
                                                onClick={() => handleSegmentChange(seg)}
                                                className={`p-2 cursor-pointer font-inter font-semibold ${segment === seg ? 'bg-custom-gray text-list-item' : 'text-list-item hover:bg-gray-300'}`}
                                            >
                                                {seg}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>

                        <div className='razao-company-resp'>
                            <p>Subsegmento</p>
                            <div className='relative w-full' onClick={toggleSubSegmentOptions}>
                                <div className={`bg-custom-blue rounded h-12 px-3 flex items-center justify-between cursor-pointer ${subSegment ? 'text-list-item' : 'text-text-placeholder'} font-inter font-regular`}>
                                    {subSegment || "Selecione"}
                                </div>
                                {showSubSegmentOptions && (
                                    <ul className='absolute bg-custom-gray rounded mt-1 w-full list-none'>
                                        {segmentData.subSegments.map((subSeg) => (
                                            <li
                                                key={subSeg}
                                                onClick={() => handleSubSegmentChange(subSeg)}
                                                className={`p-2 cursor-pointer font-inter font-semibold ${subSegment === subSeg ? 'bg-custom-gray text-list-item' : 'text-list-item hover:bg-gray-300'}`}
                                            >
                                                {subSeg}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>

                        <div className='razao-company-resp'>
                            <p>Website</p>
                            <input
                                type='text'
                                placeholder='Digite o Website da empresa'
                                className='text-inpt'
                                value={website}
                                onChange={handleWebsiteChange}
                            />
                        </div>
                        <div className='line-local'>
                            <div className='inpt-block-loc'>
                                <p>Estado</p>
                                <div className='relative w-full' onClick={toggleStateOptions} id='state-inpt'>
                                    <div className={`bg-custom-blue rounded h-12 px-3 flex items-center justify-between cursor-pointer ${state ? 'text-list-item' : 'text-text-placeholder'} font-inter font-regular`}>
                                        {state || "Selecione"}
                                    </div>
                                    {showStateOptions && (
                                        <ul className='absolute bg-custom-gray rounded mt-1 w-full list-none'>
                                            {estados.map((estado) => (
                                                <li
                                                    key={estado.sigla}
                                                    onClick={() => handleStateChange(estado.sigla)}
                                                    className={`p-2 cursor-pointer font-inter font-semibold ${state === estado.sigla ? 'bg-custom-gray text-list-item' : 'text-list-item hover:bg-gray-300'}`}
                                                >
                                                    {estado.nome}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>

                            <div className='inpt-block-loc margin-bottom'>
                                <p>Cidade</p>
                                <div className='relative w-full' onClick={toggleCityOptions} id='city-inpt'>
                                    <div className={`bg-custom-blue rounded h-12 px-3 flex items-center justify-between cursor-pointer ${city ? 'text-list-item' : 'text-text-placeholder'} font-inter font-regular`}>
                                        {city || "Selecione"}
                                    </div>
                                    {showCityOptions && (
                                        <ul className='absolute bg-custom-gray rounded mt-1 w-full list-none'>
                                            {state && cidades.map((cityName) => (
                                                <li
                                                    key={cityName}
                                                    onClick={() => handleCityChange(cityName)}
                                                    className={`p-2 cursor-pointer font-inter font-semibold ${city === cityName ? 'bg-custom-gray text-list-item' : 'text-list-item hover:bg-gray-300'}`}
                                                >
                                                    {cityName}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='razao-company-resp margin-top-1'>
                            <input
                                type='text'
                                placeholder='Complete o endereço (rua, bairro, nº)'
                                className='text-inpt'
                                value={address} // Adicionando valor do endereço
                                onChange={handleAddressChange} // Atualizando o endereço
                            />
                        </div>

                        <button className='nxt-btn' onClick={handleNextClick}>Próximo</button>
                    </div>
                </section>
            </div>
        </section>
    );
}

export default RegisterInfo3;
