import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Cria o contexto
const AuthContext = createContext();

// Provedor de contexto
export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        return localStorage.getItem('isAuthenticated') === 'true';
    });
    const navigate = useNavigate();
    const location = useLocation();

    const login = () => {
        setIsAuthenticated(true);
        localStorage.setItem('isAuthenticated', 'true');
        // Redirecionar o usuário de volta para a rota atual após o login
        navigate(location.pathname);
    };

    const logout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem('isAuthenticated');
        navigate('/login');
    };

    useEffect(() => {
        const isRegisterRoute = location.pathname.startsWith('/register');
        const isMarketingRoute = location.pathname.startsWith('/marketing');
        // Se o usuário não estiver autenticado e a rota não for de registro ou marketing, redirecioná-lo para a página de login
        if (!isAuthenticated && !isRegisterRoute && !isMarketingRoute) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate, location.pathname]);

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Hook de uso do contexto
export const useAuth = () => useContext(AuthContext);
