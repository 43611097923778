import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Layout/footer/index';
import "../material-mkt/materialMkt.css";
import icon from '../../assets/Images/icon-father.png';

function MaterialMkt() {


    return (
        <section>

            <div>
                <section>
                    <div className='center-box-default'>
                        <img src={icon} alt="Icon" className='icon-father-login' />
                        <h2>Material Marketing</h2>
                        <p>Fácil e rápido, baixe nossos materiais de marketing</p>

                    </div>
                    <div className='line-materials'>
                        <a className='item' a href="https://drive.google.com/uc?export=download&id=1WK8kVIajgo8Kbn1_UcseQACajYmg3RR3">
                            <div className='download-icon'><svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.9997 31.1666C24.8237 31.1666 31.1663 24.824 31.1663 17C31.1663 9.17595 24.8237 2.83331 16.9997 2.83331C9.17564 2.83331 2.83301 9.17595 2.83301 17C2.83301 24.824 9.17564 31.1666 16.9997 31.1666Z" stroke="#D3D3D3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11.333 17L16.9997 22.6667L22.6663 17" stroke="#D3D3D3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17 11.3333V22.6666" stroke="#D3D3D3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            </div>
                            <div className='icon'><svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.83301 28.4375C5.83301 27.4706 6.21712 26.5433 6.90085 25.8595C7.58457 25.1758 8.51191 24.7917 9.47884 24.7917H29.1663" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M9.47884 2.91669H29.1663V32.0834H9.47884C8.51191 32.0834 7.58457 31.6992 6.90085 31.0155C6.21712 30.3318 5.83301 29.4045 5.83301 28.4375V6.56252C5.83301 5.59559 6.21712 4.66825 6.90085 3.98453C7.58457 3.3008 8.51191 2.91669 9.47884 2.91669Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            </svg></div>
                            <div className='text-box'>
                                <h3>Brandbook</h3>
                                <p>Clique aqui para baixar</p>
                            </div>
                        </a>
                        <a className='item' a href="https://drive.google.com/uc?export=download&id=1-vtgDW6xzZxklFJZ8_1xud4sX4C1eoKh">
                        <div className='download-icon'><svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.9997 31.1666C24.8237 31.1666 31.1663 24.824 31.1663 17C31.1663 9.17595 24.8237 2.83331 16.9997 2.83331C9.17564 2.83331 2.83301 9.17595 2.83301 17C2.83301 24.824 9.17564 31.1666 16.9997 31.1666Z" stroke="#D3D3D3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11.333 17L16.9997 22.6667L22.6663 17" stroke="#D3D3D3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17 11.3333V22.6666" stroke="#D3D3D3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            </div>
                            <div className='icon'><svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.29199 24.7917H5.83366C5.06011 24.7917 4.31824 24.4844 3.77126 23.9374C3.22428 23.3904 2.91699 22.6485 2.91699 21.875V7.29167C2.91699 6.51812 3.22428 5.77625 3.77126 5.22927C4.31824 4.68229 5.06011 4.375 5.83366 4.375H29.167C29.9405 4.375 30.6824 4.68229 31.2294 5.22927C31.7764 5.77625 32.0837 6.51812 32.0837 7.29167V21.875C32.0837 22.6485 31.7764 23.3904 31.2294 23.9374C30.6824 24.4844 29.9405 24.7917 29.167 24.7917H27.7087" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.4997 21.875L24.7913 30.625H10.208L17.4997 21.875Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            </div>
                            <div className='text-box'>
                                <h3>Apresentação Institucional</h3>
                                <p>Clique aqui para baixar</p>
                            </div>
                        </a>
                        <a className='item'  href="https://drive.google.com/uc?export=download&id=1A0umKALjYM6JCLCcayg70lhzPyVrRxwi">
                        <div className='download-icon'><svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.9997 31.1666C24.8237 31.1666 31.1663 24.824 31.1663 17C31.1663 9.17595 24.8237 2.83331 16.9997 2.83331C9.17564 2.83331 2.83301 9.17595 2.83301 17C2.83301 24.824 9.17564 31.1666 16.9997 31.1666Z" stroke="#D3D3D3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M11.333 17L16.9997 22.6667L22.6663 17" stroke="#D3D3D3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17 11.3333V22.6666" stroke="#D3D3D3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            </div>
                            <div className='icon'><svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.83301 10.2083V5.83331H29.1663V10.2083" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M13.125 29.1667H21.875" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.5 5.83331V29.1666" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            </svg></div>
                            <div className='text-box'>
                                <h3>Logos & Fontes</h3>
                                <p>Clique aqui para baixar</p>
                            </div>
                        </a>
                    </div>
                </section>
                <Footer />
            </div>


        </section>

    );

}

export default MaterialMkt;