import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../..//auth/AuthContext'; // Importe o contexto de autenticação
import Menu from '../components/Layout/menu/index';
import Footer from '../components/Layout/footer/index';
import "../customer-data/customerData.css";
import icon from '../../assets/Images/icon-father.png';

function CustomerData() {
    const { isAuthenticated, logout } = useAuth();

    return (
        <section>
            {isAuthenticated && (
                <div>
                    <section>
                        <div className='center-box-breve'>
                            <img src={icon} alt="Icon" className='icon-father-login' />
                            <h2>Em breve</h2>
                            <p>Novos recursos estão por vir</p>

                            <Link to="/">   <div className='back-ct'><p>Voltar</p></div> </Link>
                        </div>
                    </section>
                    <Footer />
                </div>
            )}

            {!isAuthenticated && (
                <p>Por favor, faça login para acessar a rota Analytics.</p>
            )}

        </section>

    );

}

export default CustomerData;