import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://api.father.srv.br', // URL base para testes locais
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiService = {
  getDiagnostics() {
    return apiClient.get('/diagnostics');
  },
  getCompanies() {
    return apiClient.get('/companies');
  },
  getContacts() {
    return apiClient.get('/contacts');
  },
  getStatusMatches() {
    return apiClient.get('/statusmatches');
  },
  postCompanies(data) {
    return apiClient.post('/companies', data);
  },
  postContacts(data) {
    return apiClient.post('/contacts', data);
  },
  postStatusMatches(data) {
    return apiClient.post('/status-mach', data);
  },
  getRounds() {
    return apiClient.get('/rounds'); // Adicionado o método getRounds
  },
  postRounds(data) {
    return apiClient.post('/rounds', data);
  },
  // Adicione outros métodos conforme necessário
};

export default apiService;
