import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

const PrivateRoute = ({ element }) => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();

    // Verifica se a rota é protegida
    const isProtectedRoute = location.pathname.startsWith('/diagnostics') || location.pathname.startsWith('/analytics') || location.pathname.startsWith('/customers') || location.pathname === '/';

    // Verifica se a rota é de registro ou marketing
    const isRegisterRoute = location.pathname.startsWith('/register');
    const isMarketingRoute = location.pathname.startsWith('/marketing');

    // Permite o acesso às rotas de registro e marketing mesmo que o usuário não esteja autenticado
    if (isRegisterRoute || isMarketingRoute) {
        return element;
    }

    // Redireciona para a página de login se a rota for protegida e o usuário não estiver autenticado
    if (isProtectedRoute && !isAuthenticated) {
        return <Navigate to="/login" />;
    }

    // Retorna o componente se a rota não for protegida ou se o usuário estiver autenticado
    return element;
};

export default PrivateRoute;
