// App.js

import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Home from './pages/home/index';
import Login from './pages/login/index';
import Diagnostics from './pages/diagnostics/index';
import Analytics from './pages/analytics/index';
import { AuthProvider } from './auth/AuthContext';
import PrivateRoute from './auth/PrivateRoute';
import CustomerData from './pages/customer-data/index';
import RegisterInfo1 from './pages/customer-register/company-register/info-1';
import RegisterInfo2 from './pages/customer-register/company-register/info-2';
import RegisterInfo3 from './pages/customer-register/company-register/info-3';
import Status from './pages/customer-register/company-register/status';
import CtInfo1 from './pages/customer-register/contact-register/ct-info-1';
import CtInfo2 from './pages/customer-register/contact-register/ct-info-2';
import Sucess from './pages/customer-register/sucess';
import MaterialMkt from './pages/material-mkt';
import getConnect from './pages/get-connect';
import Rounds from './pages/rounds';

function App() {
  const [location, setLocation] = useState('');
  const [registerStep, setRegisterStep] = useState(1);
  const [formData, setFormData] = useState({
    country: '',
    companyName: '',
    companySocialReason: '',
    cnpj: '',
    fiscalClass: '',
    segment: '',
    subSegment: '',
    state: '',
    city: '',
    website: '',
    address: '',
    role: '',
    sector: '',
    contactName: '',
    email: '',
    phone: '',
    birthDate: ''
  });

  const handleFormDataUpdate = (data) => {
    setFormData(prevState => ({ ...prevState, ...data }));
  };

  const resetFormData = () => {
    setFormData(prevState => ({
      country: '',
      companyName: '',
      companySocialReason: '',
      cnpj: prevState.cnpj,
      fiscalClass: '',
      segment: '',
      subSegment: '',
      state: '',
      city: '',
      website: '',
      address: '',
      role: '',
      sector: '',
      contactName: '',
      email: '',
      phone: '',
      birthDate: ''
    }));
    setRegisterStep(5);
  };

  const renderRegisterComponent = () => {
    switch (registerStep) {
      case 1:
        return <RegisterInfo1 setFormData={handleFormDataUpdate} setRegisterStep={setRegisterStep} />;
      case 2:
        return <RegisterInfo2 location={location} formData={formData} setFormData={handleFormDataUpdate} setRegisterStep={setRegisterStep} />;
      case 3:
        return <RegisterInfo3 location={location} formData={formData} setFormData={handleFormDataUpdate} setRegisterStep={setRegisterStep} />;
      case 4:
        return <Status location={location} formData={formData} setFormData={handleFormDataUpdate} setRegisterStep={setRegisterStep} />;
      case 5:
        return <CtInfo1 location={location} formData={formData} setFormData={handleFormDataUpdate} setRegisterStep={setRegisterStep} />;
      case 6:
        return <CtInfo2 location={location} formData={formData} setFormData={handleFormDataUpdate} setRegisterStep={setRegisterStep} />;
      case 7:
        return <Sucess location={location} formData={formData} setFormData={handleFormDataUpdate} setRegisterStep={setRegisterStep} resetFormData={resetFormData}/>;
      default:
        return <RegisterInfo1 setLocation={setLocation} setRegisterStep={setRegisterStep} />;
    }
  };

  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<PrivateRoute element={<Home />} />} />
          <Route path='/diagnostics' element={<PrivateRoute element={<Diagnostics />} />} />
          <Route path='/analytics' element={<PrivateRoute element={<Analytics />} />} />
          <Route path='/customers' element={<PrivateRoute element={<CustomerData />} />} />
          <Route path='/getconnect' element={<PrivateRoute element={<getConnect />} />} />
          <Route path='/rounds' element={<PrivateRoute element={<Rounds />} />} />
          <Route path='/marketing' element={<MaterialMkt />} />
          <Route path='/register' element={renderRegisterComponent()} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
