import React, { useState } from 'react';
import './NewRoundOverlay.css';
import apiService from '../../../services/apiService';

function NewRoundOverlay({ onClose }) {
    // Estados para armazenar os dados do formulário e a chave gerada
    const [roundData, setRoundData] = useState({
        nameRound: '',
        localRound: '',
        modelRound: '',
        startDate: '',
        endDate: '',
        statusRound: 'active', // Presumindo que o status inicial seja 'active'
        keyRound: '',
    });

    // Função para aplicar a máscara de data
    const handleDateChange = (e) => {
        const { value, name } = e.target;
        let newValue = value.replace(/\D/g, ''); // Remove qualquer caractere que não seja número
        if (newValue.length > 2) newValue = newValue.slice(0, 2) + '/' + newValue.slice(2);
        if (newValue.length > 5) newValue = newValue.slice(0, 5) + '/' + newValue.slice(5, 7);
        setRoundData(prevState => ({ ...prevState, [name]: newValue }));
    };

    // Função para manipular as mudanças de outros inputs
    const handleChange = (e) => {
        const { name, value } = e.target;
        setRoundData(prevState => ({ ...prevState, [name]: value }));
    };

    // Função para gerar uma chave aleatória
    const generateRandomKey = () => {
        return Math.random().toString(36).substring(2, 15);
    };

    // Função para manipular o envio do formulário
    const handleSubmit = async (e) => {
        e.preventDefault();
        const key = generateRandomKey();

        // Converter as datas do formato 'DD/MM/YY' para objetos Date
        const startDateParts = roundData.startDate.split('/');
        const endDateParts = roundData.endDate.split('/');

        const updatedRoundData = {
            ...roundData,
            keyRound: key,
            startDate: new Date(`20${startDateParts[2]}-${startDateParts[1]}-${startDateParts[0]}`),
            endDate: new Date(`20${endDateParts[2]}-${endDateParts[1]}-${endDateParts[0]}`),
        };
        
        try {
            const response = await apiService.postRounds(updatedRoundData);
            console.log('Resposta da API:', response.data);
            // Você pode adicionar uma mensagem de sucesso aqui ou realizar outras ações
        } catch (error) {
            console.error('Erro ao enviar os dados:', error);
            // Você pode adicionar uma mensagem de erro aqui ou realizar outras ações
        }

        onClose(); // Fecha o overlay após o envio
    };

    return (
        <div className="overlay">
            <div className="overlay-content">
                <div className="close-button">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClose}>
                        <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#1D1B20" />
                    </svg>
                </div>
                <h2>Criar Nova Rodada</h2>
                <form className='formNewRound' onSubmit={handleSubmit}>
                    <label className='labelRound'>Nome da rodada</label>
                    <input
                        className='inptRound'
                        name="nameRound"
                        placeholder='Digite o nome da rodada aqui'
                        value={roundData.nameRound}
                        onChange={handleChange}
                    />
                    <label className='labelRound'>Local da rodada</label>
                    <input
                        className='inptRound'
                        name="localRound"
                        placeholder='Digite o local da rodada aqui'
                        value={roundData.localRound}
                        onChange={handleChange}
                    />
                    <label className='labelRound'>Modalidade</label>
                    <input
                        className='inptRound'
                        name="modelRound"
                        placeholder='Online, presencial ou híbrido.'
                        value={roundData.modelRound}
                        onChange={handleChange}
                    />
                    <div className='lineDataRound'>
                        <div className='dataRound dataRoundW1'>
                            <label className='labelRound'>Data início</label>
                            <input
                                className='inptRound dateInptSmall'
                                name="startDate"
                                placeholder='DD/MM/YY'
                                value={roundData.startDate}
                                onChange={handleDateChange}
                            />
                        </div>
                        <div className='dataRound dataRoundW2'>
                            <label className='labelRound'>Data final</label>
                            <input
                                className='inptRound dateInptSmall'
                                name="endDate"
                                placeholder='DD/MM/YY'
                                value={roundData.endDate}
                                onChange={handleDateChange}
                            />
                        </div>
                    </div>
                    <button className='action' type="submit">
                        <p>Criar rodada</p>
                    </button>
                </form>
            </div>
        </div>
    );
}

export default NewRoundOverlay;

