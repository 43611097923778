import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';
import "./diagnostics.css";
import apiService from '../../services/apiService';
import Menu from '../components/Layout/menu/index';
import Footer from '../components/Layout/footer/index';

function Diagnostics() {
    const { isAuthenticated, logout } = useAuth();
    const [resposta, setResposta] = useState([]);
    const [showOverlay, setShowOverlay] = useState(false);
    const [selectedDiagnosis, setSelectedDiagnosis] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filter, setFilter] = useState('all');
    const [filterLocalization, setFilterLocalization] = useState('all');
    const [filterDate, setFilterDate] = useState('');
    const [localizationFilter, setLocalizationFilter] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchDataApi = async () => {
            try {
                const response = await apiService.getDiagnostics();
                console.log('Response Data:', response.data);

                const diagnostics = response.data;

                if (Array.isArray(diagnostics)) {
                    const sortedDiagnostics = diagnostics.sort((a, b) => {
                        if (!a.infoDiagnostic.data_envio && !b.infoDiagnostic.data_envio) {
                            return 0;
                        } else if (!a.infoDiagnostic.data_envio) {
                            return 1;
                        } else if (!b.infoDiagnostic.data_envio) {
                            return -1;
                        } else {
                            return new Date(b.infoDiagnostic.data_envio) - new Date(a.infoDiagnostic.data_envio);
                        }
                    });

                    setResposta(sortedDiagnostics);
                } else {
                    console.error('Expected an array but got:', diagnostics);
                }
            } catch (error) {
                console.error('Erro:', error);
            }
        };

        fetchDataApi();
    }, []);

    const handleOptionsClick = (event, diagnosis) => {
        event.stopPropagation();
        setSelectedDiagnosis(diagnosis);
        setShowOverlay(true);
    };

    const handleDelete = async () => {
        if (selectedDiagnosis && selectedDiagnosis._id) {
            const id = selectedDiagnosis._id;
            try {
                const index = resposta.findIndex(item => item._id === id);
                if (index !== -1) {
                    await apiService.delete(`/api/v1/diagnostics/${id}`);
                    setResposta(resposta.filter(item => item._id !== id));
                    setShowOverlay(false);
                    setSelectedDiagnosis(null);
                } else {
                    console.error('Diagnosis not found in local state.');
                }
            } catch (error) {
                console.error('Erro ao deletar:', error);
            }
        }
    };

    const handleDiagnosisClick = (item) => {
        localStorage.setItem('infoDiagnostic', JSON.stringify(item.infoDiagnostic));
        navigate('/analytics');
    };

    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setSearchTerm(searchTerm);
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        if (name === 'filter') {
            setFilter(value);
        } else if (name === 'filterDate') {
            setFilterDate(value);
        } else if (name === 'filterLocalization') {
            setFilterLocalization(value);
        }
    };

    const handleLocalizationFilterChange = (event) => {
        setLocalizationFilter(event.target.value);
    };

    const getBackgroundColor = (avgGeneral) => {
        if (avgGeneral <= 5.5) {
            return '#FFB2B2';
        } else if (avgGeneral > 5.5 && avgGeneral < 8) {
            return '#FFE9B2';
        } else if (avgGeneral >= 8) {
            return '#B8FFB2';
        } else {
            return 'transparent';
        }
    };

    const filteredDiagnostics = resposta.filter(item => {
        const matchesSearchTerm = (
            (item.infoDiagnostic.company && item.infoDiagnostic.company.toLowerCase().includes(searchTerm)) ||
            (item.infoDiagnostic.name && item.infoDiagnostic.name.toLowerCase().includes(searchTerm)) ||
            (item.infoDiagnostic.cnpj && item.infoDiagnostic.cnpj.toLowerCase().includes(searchTerm))
        );

        const matchesFilter = (filter === 'all') ||
            (filter === 'product' && item.infoDiagnostic.segment === 'product') ||
            (filter === 'service' && item.infoDiagnostic.segment === 'service');

        const matchesDate = !filterDate || new Date(item.infoDiagnostic.data_envio).toISOString().includes(filterDate);

        const matchesLocalization = (filterLocalization === 'all') ||
            (item.infoDiagnostic.localization === filterLocalization);

        return matchesSearchTerm && matchesFilter && matchesDate && matchesLocalization;
    });

    return (
        <section>
            {isAuthenticated && (
                <div>
                    <Menu />
                </div>
            )}

            {!isAuthenticated && (
                <p>Por favor, faça login para acessar a rota Analytics.</p>
            )}

            <div className='center-results'>
                <div className='line-title'>
                    <svg width="39" height="36" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27 13.25H22L18.25 24.5L10.75 2L7 13.25H2" stroke="#1D1B20" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <h1>Diagnósticos</h1>
                    <p>Confira os diagnósticos enviados</p>
                </div>
                <div className='list-box'>
                    <div className='line-filters'>
                        <div className='search-box'>
                            <input
                                className='search-inpt'
                                placeholder='Pesquisar diagnósticos'
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </div>
                        <div className='r-filters'>
                            <div className='new-filter-2'>
                                <select value={filter} name="filter" onChange={handleFilterChange}>
                                    <option value="all">Segmento</option>
                                    <option value="product">Produto</option>
                                    <option value="service">Serviço</option>
                                </select>
                            </div>

                            <div className='new-filter'>
                                <select value={filterLocalization} name="filterLocalization" onChange={handleFilterChange}>
                                    <option value="all">Origem</option>
                                    <option value="Consultoria">Consultoria</option>
                                    <option value="Hub das américas">Hub das Américas</option>
                                    <option value="Anúncio de Internet">Anúncio de Internet</option>
                                    <option value="Imprensa">Imprensa</option>
                                    <option value="GTM SEBRAE">GTM SEBRAE</option>
                                </select>
                            </div>

                            <div className='export'>
                                <div className='filter-content-exp'>
                                    <svg width="22" height="22" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.7782 11.6113V13.7224C14.7782 14.0024 14.6546 14.2709 14.4347 14.4688C14.2147 14.6668 13.9164 14.778 13.6054 14.778H5.3955C5.08444 14.778 4.78612 14.6668 4.56617 14.4688C4.34622 14.2709 4.22266 14.0024 4.22266 13.7224V11.6113" stroke="#C3C3C3" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M6.33301 8.44434L9.49967 11.611L12.6663 8.44434" stroke="#C3C3C3" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9.5 11.6111V4.22217" stroke="#C3C3C3" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <p>Exportar</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="infos-box">
                        <div className="table-container">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th style={{ width: '20%' }}>Empresa</th>
                                        <th>Usuário</th>
                                        <th style={{ width: '10%' }}>Score Global</th>
                                        <th>Data Envio</th>
                                        <th>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredDiagnostics.length === 0 ? (
                                        <tr>
                                            <td colSpan="6">Nenhum diagnóstico encontrado.</td>
                                        </tr>
                                    ) : (
                                        filteredDiagnostics.map((item, index) => (
                                            <tr key={item._id} onClick={() => handleDiagnosisClick(item)}>
                                                <td className="first-column">{index + 1}</td>
                                                <td>{item.infoDiagnostic.company || 'Empresa não disponível'}</td>
                                                <td>{item.infoDiagnostic.name}</td>
                                                <td>
                                                    <div 
                                                        className='l-score-global' 
                                                        style={{ backgroundColor: getBackgroundColor(item.infoDiagnostic.avgGeneral) }}
                                                    >
                                                        {item.infoDiagnostic.avgGeneral}
                                                    </div>
                                                </td>
                                                <td>{item.infoDiagnostic.data_envio ? new Date(item.infoDiagnostic.data_envio).toLocaleDateString() : 'Não disponível'}</td>
                                                <td>
                                                    <button className='more-ops' onClick={(event) => handleOptionsClick(event, item)}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg></button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    );
}

export default Diagnostics;
